import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { SearchView } from "@/components";
import {
  approveInvoice,
  getAllInvoices,
} from "@/data/requests/invoice-requests";
import { FullScreenLayout } from "../shared/fullscreen-layout";
import { countryOptions } from "@/utils/country-options";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const STATUS_OPTIONS = [
  { value: "Approved", label: "Approved" },
  { value: "Change Requested", label: "Change Requested" },
  { value: "Troubled", label: "Troubled" },
  { value: "Invoice Uploaded", label: "Invoice Uploaded" },
  { value: "Mail Sended", label: "Mail Sended" },
  { value: "Accounting", label: "Accounting" },
  { value: "Auto Created Invoice", label: "Auto Created Invoice" },
  { value: "OCR Completed", label: "OCR Completed" },
  { value: "LUCA", label: "LUCA" },
  { value: "Paid", label: "Paid" },
  { value: "all", label: "All" },
];

export function AccountingOnePageScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [pageSize] = useState(1);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    country: "",
  });
  const [queryParams, setQueryParams] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const pdfRef = useRef(null);
  const [pdfContainerWidth, setPdfContainerWidth] = useState(null);

  useEffect(() => {
    const updateWidth = () => {
      if (pdfRef.current) {
        setPdfContainerWidth(pdfRef.current.clientWidth);
      }
    };

    // Initial width calculation
    updateWidth();

    // Update width on window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup
    return () => window.removeEventListener("resize", updateWidth);
  }, [pdfContainerWidth]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["invoices", page, pageSize, queryParams],
    queryFn: () =>
      getAllInvoices({
        page,
        pageSize,
        nameFilter: queryParams.name,
        emailNameFilter: queryParams.email,
        lucaIdFilter: queryParams.lucaId,
        statusFilter: "Accounting",
        countryFilter: queryParams.country,
      }),
  });

  const approveInvoiceMutation = useMutation({
    mutationFn: approveInvoice,
    onSuccess: () => {
      queryClient.invalidateQueries(["invoices-all"]);
      refetch();
      handleNextInvoice();
    },
  });

  const handleUpdateStatus = (invoiceId, newStatus) => {
    approveInvoiceMutation.mutate({
      id: invoiceId,
      data: { status: newStatus },
    });
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const handleNextInvoice = () => {
    const totalItems = data?.data?.totalItems || 0;
    if (page * pageSize < totalItems) {
      setPage(page + 1);
    } else {
      // If we're at the last item, show a message or handle accordingly
      alert("No more invoices to process");
    }
  };

  return (
    <FullScreenLayout>
      <div className="h-screen">
        <ResizablePanelGroup
          direction="horizontal"
          className="min-h-[800px] rounded-lg border"
        >
          <ResizablePanel defaultSize={30}>
            <div className="h-full p-6 overflow-auto">
              <SearchView
                searchParams={searchParams}
                handleSearch={handleSearch}
                countryOptions={countryOptions}
                resetDefault={() => {
                  setSearchParams({
                    country: "",
                  });
                  setQueryParams({});
                  setPage(1);
                }}
              />

              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <p>Loading...</p>
                </div>
              ) : data?.data?.commissionDetails?.length > 0 ? (
                <Card className="mb-4">
                  <CardHeader>
                    <CardTitle>Invoice Details</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <p className="text-sm font-medium">Luca ID:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.commission?.client
                            ?.lucaId || "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">
                          Luca Account Name:
                        </p>
                        <p>
                          {data?.data?.commissionDetails[0]?.commission?.client
                            ?.lucaAccountName || "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Invoice ID:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.invoiceId || "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Invoice Date:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.invoiceDate ||
                            "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Total Amount:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.totalAmount ||
                            "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Currency:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.commission?.client
                            ?.currency || "N/A"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Status:</p>
                        <p>
                          {data?.data?.commissionDetails[0]?.status || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 mt-4">
                      {data?.data?.commissionDetails[0]?.status !==
                        "LUCAREADY" && (
                        <Button
                          onClick={() =>
                            handleUpdateStatus(
                              data?.data?.commissionDetails[0]?.id,
                              "LUCAREADY"
                            )
                          }
                          disabled={
                            data?.data?.commissionDetails[0]?.status ===
                            "LUCAREADY"
                          }
                        >
                          READY
                        </Button>
                      )}

                      <Button
                        variant="outline"
                        onClick={() =>
                          navigate(
                            `/payments/${data?.data?.commissionDetails[0]?.commission?.paymentId}/invoices/${data?.data?.commissionDetails[0]?.id}`
                          )
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                <Card>
                  <CardContent className="pt-6">
                    <p>No invoices found</p>
                  </CardContent>
                </Card>
              )}

              <div className="flex justify-end mt-4">
                <Button
                  onClick={handleNextInvoice}
                  disabled={isLoading || !data?.data?.commissionDetails[0]}
                >
                  Next Invoice
                </Button>
              </div>

              {data?.data?.totalItems > 0 && (
                <div className="text-sm text-gray-500 mt-2 text-right">
                  Invoice {page} of{" "}
                  {Math.ceil((data?.data?.totalItems || 0) / pageSize)}
                </div>
              )}
            </div>
          </ResizablePanel>

          <ResizableHandle withHandle />

          <ResizablePanel defaultSize={70}>
            <div className="m-1 mr-4 border border-blue-300 rounded-lg h-screen overflow-auto">
              {data?.data?.commissionDetails[0]?.invoicePath &&
              data?.data?.commissionDetails[0]?.invoicePath.includes(".pdf") ? (
                <div ref={pdfRef} className="flex h-screen w-full">
                  <Document
                    file={
                      "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                      data?.data?.commissionDetails[0]?.invoicePath.replace(
                        "public/uploads/paymentPdfs/",
                        ""
                      )
                    }
                  >
                    <Page
                      pageNumber={1}
                      width={pdfContainerWidth || 1200}
                      renderTextLayer={false}
                    />
                  </Document>
                </div>
              ) : (
                <div className="flex flex-col gap-1 border border-gray-300 rounded-lg m-4 h-screen pb-20">
                  {data?.data?.commissionDetails[0]?.invoicePath && (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={
                        "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                        data?.data?.commissionDetails[0]?.invoicePath.replace(
                          "public/uploads/paymentPdfs/",
                          ""
                        )
                      }
                    />
                  )}
                  {!data?.data?.commissionDetails[0]?.invoicePath && (
                    <Card>
                      <CardHeader>
                        <CardTitle>Invoice Preview</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p>No invoice selected</p>
                      </CardContent>
                    </Card>
                  )}
                </div>
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </FullScreenLayout>
  );
}
