import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  render,
  PDFViewer,
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Roboto",
    width: "1123px",
    height: "794px",
    fontSize: 12,
    fontWeight: "200",
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

// Create Document Component
export const InvoicePdfEN = ({ data }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View
        style={{
          display: "flex",
          flexDirection: "column",

          height: "100%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              marginTop: "10px",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              fontSize: "30px",
            }}
          >
            <Text>INVOICE </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flex: 1 }}>
              <View style={styles.section}>
                <Text>Name Surname: {data.recipientName}</Text>
              </View>
              <View style={styles.section}>
                <Text>E-Mail: {data.email}</Text>
              </View>
              <View style={styles.section}>
                <Text>Phone Number : {data.phoneNumber}</Text>
              </View>
            </View>
            <View
              style={{ flex: 1, justifyContent: "center", marginLeft: "100px" }}
            >
              <View style={styles.section}>
                <Text>Invoice Number: {data.invoiceId}</Text>
              </View>

              {data.lucaId && (
                <View style={styles.section}>
                  <Text>Luca Id: {data.lucaId}</Text>
                </View>
              )}

              <View style={styles.section}>
                <Text>Invoice Date: {data.invoiceDate}</Text>
              </View>
              <View style={styles.section}>
                <Text>ENG İş Geliştirme ve Yatırım Ltd. Şti </Text>
                <Text>Kucukyali Merkez Mh. Eski Bağdat Cad 10/8</Text>
                <Text>Maltepe/İstanbul Küçükyalı </Text>
                <Text>Türkiye VD 335 044 9019</Text>
              </View>
            </View>
          </View>
          <View style={{ alignItems: "center" }}>
            <View>
              <Text>{data.country} trendyol influencer marketing payment</Text>
            </View>
            <View style={styles.section}>
              <Text
                style={{
                  fontSize: "30px",
                }}
              >
                {data.finalAmount}{" "}
                {data.country?.toLowerCase() == "mena" ? "$" : "€"}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View style={styles.section}>
                <Text>IBAN: {data.iban}</Text>
              </View>
              <View style={styles.section}>
                <Text>SWIFT: {data.swift}</Text>
              </View>
            </View>
            <View>
              <View style={styles.section}>
                <Text>Bank Account Holder: {data.bankAccountHolder}</Text>
              </View>
            </View>
            <View style={{ justifyContent: "flex-end", margin: 10 }}>
              <View
                style={{
                  border: 1,
                  height: 80,
                  width: 250,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text>Signature or Initials Please</Text>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                    {data.recipientName}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
