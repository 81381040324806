import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { InvoiceCard, SearchView } from "../../../components";
import { getInvoices, downloadInvoicesExcel } from "../../../data";
import ListView from "../../../components/list-view";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  approveInvoice,
  downloadFilteredInvoicesAllExcel,
  downloadFilteredInvoicesExcel,
  getAllInvoices,
  getAllInvoicesOperations,
  uploadInvoiceStatusExcel,
  uploadBulkPaymentExcel,
  downloadFilteredInvoicesAllBankExcel,
  getPaymentHistoryGroups,
  getPaymentHistoryByGroupId,
  downloadGroupInvoicePdfs,
  getCommissionCountryGroupedInvoiceIds,
} from "@/data/requests/invoice-requests";
import { countryOptions } from "@/utils/country-options";
import { useToast } from "@/components/ui/use-toast";
import * as XLSX from "xlsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";

const STATUS_OPTIONS = [
  { value: "Approved", label: "Approved" },
  { value: "Change Requested", label: "Change Requested" },
  { value: "Troubled", label: "Troubled" },
  { value: "Invoice Uploaded", label: "Invoice Uploaded" },
  { value: "Mail Sended", label: "Mail Sended" },
  { value: "Accounting", label: "Accounting" },
  { value: "Auto Created Invoice", label: "Auto Created Invoice" },
  { value: "OCR Completed", label: "OCR Completed" },
  { value: "LUCA", label: "LUCA" },
  { value: "InBank", label: "InBank" },
  { value: "Paid", label: "Paid" },
  { value: "PAID-TIMEOUT", label: "PAID-TIMEOUT" },
  { value: "all", label: "All" },
];

export function InvoiceListOperationScreen() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [pageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    email: "",
    lucaId: "",
    status: "",
    country: "",
  });
  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isGroupDetailsOpen, setIsGroupDetailsOpen] = useState(false);
  const [isInvoiceSelectionOpen, setIsInvoiceSelectionOpen] = useState(false);
  const [groupedInvoices, setGroupedInvoices] = useState([]);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);

  const navigate = useNavigate();

  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem(`invoiceListPage-operations`);
    return storedPage ? parseInt(storedPage, 10) : 1;
  });
  const [queryParams, setQueryParams] = useState(() => {
    const storedQueryParams = localStorage.getItem(
      `invoiceListQueryParams-operations`
    );
    if (storedQueryParams && storedQueryParams !== "{}") {
      setSearchParams(JSON.parse(storedQueryParams));
    }
    return storedQueryParams ? JSON.parse(storedQueryParams) : {};
  });
  useEffect(() => {
    localStorage.setItem(`invoiceListPage-operations`, page.toString());
    localStorage.setItem(
      `invoiceListQueryParams-operations`,
      JSON.stringify(queryParams)
    );
  }, [page, queryParams]);

  const { data, isLoading, error } = useQuery({
    queryKey: ["invoices", page, pageSize, queryParams],
    queryFn: () =>
      getAllInvoicesOperations({
        page,
        pageSize,
        nameFilter: queryParams.name,
        emailNameFilter: queryParams.email,
        lucaIdFilter: queryParams.lucaId,
        statusFilter: queryParams.status,
        countryFilter: queryParams.country,
      }),
    onError: (error) => {
      toast({
        title: "Error loading invoices",
        description:
          error.response?.data?.message || "An unexpected error occurred",
        variant: "destructive",
      });
    },
  });

  const { data: paymentHistoryGroups, isLoading: isLoadingPaymentHistory } =
    useQuery({
      queryKey: ["paymentHistoryGroups"],
      queryFn: getPaymentHistoryGroups,
      enabled: isPaymentHistoryOpen,
      onError: (error) => {
        toast({
          title: "Error loading payment history",
          description:
            error.response?.data?.message || "An unexpected error occurred",
          variant: "destructive",
        });
      },
    });

  const { data: groupDetails, isLoading: isLoadingGroupDetails } = useQuery({
    queryKey: ["paymentHistoryGroup", selectedGroupId],
    queryFn: () => getPaymentHistoryByGroupId(selectedGroupId),
    enabled: !!selectedGroupId && isGroupDetailsOpen,
    onError: (error) => {
      toast({
        title: "Error loading group details",
        description:
          error.response?.data?.message || "An unexpected error occurred",
        variant: "destructive",
      });
    },
  });

  const downloadExcel = () => {
    if (queryParams.status !== "LUCA" && queryParams.status !== "InBank") {
      toast({
        title: "Error downloading Excel file",
        description: "Status filter must be LUCA or InBank",
        variant: "destructive",
      });
      return;
    }

    setIsLoadingInvoices(true);

    // Get invoice IDs and open the selection dialog
    getCommissionCountryGroupedInvoiceIds(queryParams.country)
      .then((response) => {
        console.log("Invoices response:", response.data);

        // Ensure data has the expected structure
        if (response.data && Array.isArray(response.data)) {
          setGroupedInvoices(response.data);
        } else {
          console.error("Unexpected API response format:", response.data);
          setGroupedInvoices([]);
          toast({
            title: "API Response Format Error",
            description: "The server returned an unexpected data format",
            variant: "destructive",
          });
        }

        setSelectedInvoiceIds([]);
        setIsInvoiceSelectionOpen(true);
        setIsLoadingInvoices(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setIsLoadingInvoices(false);
        toast({
          title: "Error loading invoice IDs",
          description:
            error.response?.data?.message || "An unexpected error occurred",
          variant: "destructive",
        });
      });
  };

  const handleDownloadSelectedInvoices = () => {
    if (selectedInvoiceIds.length === 0) {
      toast({
        title: "No invoices selected",
        description: "Please select at least one invoice to download",
        variant: "destructive",
      });
      return;
    }

    // Join the selected invoice IDs with commas
    const invoiceIdsString = selectedInvoiceIds.join(",");

    // Show a loading toast
    toast({
      title: "Preparing download",
      description: `Generating Excel file with ${selectedInvoiceIds.length} invoices...`,
      variant: "default",
    });

    downloadFilteredInvoicesAllBankExcel({
      nameFilter: queryParams.name,
      emailNameFilter: queryParams.email,
      lucaIdFilter: queryParams.lucaId,
      statusFilter: queryParams.status,
      countryFilter: queryParams.country,
      invoiceIdFilter: invoiceIdsString ? invoiceIdsString : undefined,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "bank_account_invoices.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Close the dialog and show success message
        setIsInvoiceSelectionOpen(false);
        toast({
          title: "Download successful",
          description: `Excel file with ${selectedInvoiceIds.length} invoices has been downloaded.`,
          variant: "default",
        });
      })
      .catch((error) => {
        console.error("Error downloading Excel:", error);
        toast({
          title: "Error downloading Excel file",
          description:
            error.response?.data?.message || "An unexpected error occurred",
          variant: "destructive",
        });
      });
  };

  const selectAllInvoices = () => {
    if (groupedInvoices && groupedInvoices.length > 0) {
      // Extract all invoice IDs
      const allIds = groupedInvoices.map((item) => item.invoiceId);
      setSelectedInvoiceIds(allIds);
    }
  };

  const toggleInvoiceSelection = (invoiceId) => {
    setSelectedInvoiceIds((prev) => {
      if (prev.includes(invoiceId)) {
        return prev.filter((id) => id !== invoiceId);
      } else {
        return [...prev, invoiceId];
      }
    });
  };

  const clearAllInvoices = () => {
    setSelectedInvoiceIds([]);
  };

  const uploadBankExcel = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      // Show loading notification
      toast({
        title: "Uploading Excel file...",
        description: "Please wait while we process your file.",
        variant: "default",
      });

      // Validate the Excel file before uploading
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          // Check if the Excel file has data
          if (!jsonData || jsonData.length === 0) {
            toast({
              title: "Error with Excel file",
              description: "The Excel file is empty or has no data.",
              variant: "destructive",
            });
            document.getElementById("uploadExcel").value = "";
            return;
          }

          // Check if required fields exist in the first row
          const firstRow = jsonData[0];
          if (
            !firstRow.hasOwnProperty("id") ||
            !firstRow.hasOwnProperty("amount")
          ) {
            toast({
              title: "Error with Excel file",
              description:
                "The Excel file is missing required columns: 'id' and 'amount'. Please download the sample template.",
              variant: "destructive",
            });
            document.getElementById("uploadExcel").value = "";
            return;
          }

          // If validation passes, proceed with upload
          uploadBulkPaymentExcel(formData)
            .then((response) => {
              // Show success message with details
              const successCount = response.data.results.success.length;
              const failedCount = response.data.results.failed.length;

              toast({
                title: "Excel file processed successfully",
                description: `Successfully updated ${successCount} invoices. ${
                  failedCount > 0
                    ? `Failed to update ${failedCount} invoices.`
                    : ""
                }`,
                variant: "default",
              });

              // If there are failures, show details in console
              if (failedCount > 0) {
                console.log("Failed updates:", response.data.results.failed);
              }

              // Refresh the data
              queryClient.invalidateQueries([
                "invoices",
                page,
                pageSize,
                queryParams,
              ]);

              // Also refresh payment history groups
              queryClient.invalidateQueries(["paymentHistoryGroups"]);
            })
            .catch((error) => {
              // Show error message with more details
              let errorMessage = "An unexpected error occurred";

              if (error.response?.data?.message) {
                errorMessage = error.response.data.message;
              }

              // Add more specific error details if available
              if (error.response?.data?.error) {
                errorMessage += `: ${error.response.data.error}`;
              }

              // Handle specific errors
              if (
                error.response?.data?.error?.includes(
                  "Cannot read properties of undefined"
                )
              ) {
                errorMessage =
                  "Excel format error: The Excel file structure doesn't match what's expected. Make sure your Excel file has the required fields (id and amount) and that the id values match existing invoices. Download the sample template for reference.";
              }

              toast({
                title: "Error uploading Excel file",
                description: errorMessage,
                variant: "destructive",
              });

              console.error("Error uploading Excel:", error);

              // Reset the file input
              document.getElementById("uploadExcel").value = "";
            });
        } catch (error) {
          toast({
            title: "Error reading Excel file",
            description:
              "The file could not be read as an Excel file. Please check the file format.",
            variant: "destructive",
          });
          document.getElementById("uploadExcel").value = "";
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("uploadExcel").click();
  };

  const handleViewPaymentHistory = () => {
    setIsPaymentHistoryOpen(true);
  };

  const handleViewGroupDetails = (groupId) => {
    setSelectedGroupId(groupId);
    setIsGroupDetailsOpen(true);
  };

  const handleDownloadGroupPdfs = (groupId) => {
    downloadGroupInvoicePdfs(groupId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoices-${groupId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast({
          title: "Download successful",
          description: "Invoice PDFs have been downloaded as a ZIP file.",
          variant: "default",
        });
      })
      .catch((error) => {
        toast({
          title: "Error downloading PDFs",
          description:
            error.response?.data?.message || "An unexpected error occurred",
          variant: "destructive",
        });
        console.error("Error downloading PDFs:", error);
      });
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    try {
      const date = new Date(dateString);
      return format(date, "dd.MM.yyyy HH:mm");
    } catch (error) {
      return dateString;
    }
  };

  const pageSettings = {
    title: "Invoice Operations",
    actions: [
      {
        name: "Download Bank Excel",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        onClick: downloadExcel,
      },
      {
        name: "Upload Bulk Paid Excel",
        color: "bg-green-500 hover:bg-green-700 text-white",
        onClick: handleUploadClick,
      },
      {
        name: "Payment (Paid) History",
        color: "bg-purple-500 hover:bg-purple-700 text-white",
        onClick: handleViewPaymentHistory,
      },
    ],
  };

  const columns = [
    {
      key: "name",
      label: "Name",
      accessor: (item) => item.commission?.client?.name || "",
    },
    {
      key: "email",
      label: "Email",
      accessor: (item) => item.commission?.email || "",
    },
    {
      key: "invoiceId",
      label: "Our Invoice Date",
      accessor: (item) => item.commission?.invoiceId || "",
    },
    {
      key: "invoiceId",
      label: "Invoice ID",
      accessor: (item) => item.invoiceId || "",
    },
    {
      key: "bankAccountHolder",
      label: "Bank Account Holder",
      accessor: (item) => item.bankAccountHolder || "",
    },
    {
      key: "lucaAccountName",
      label: "Luca Account Name",
      accessor: (item) => item.commission?.client?.lucaAccountName || "",
    },
    {
      key: "lucaId",
      label: "Luca ID",
      accessor: (item) => item.commission?.client?.lucaId || "",
    },
    {
      key: "commission.finalAmount",
      label: "Final Amount",
      accessor: (item) => item.commission?.finalAmount || "",
    },
    { key: "status", label: "Status" },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div className="flex gap-2">
          <Button
            onClick={() =>
              navigate(
                `/payments/${row.commission.paymentId}/invoices/${row.id}`
              )
            }
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      fullscreen={true}
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
      style={{ padding: "0 10px" }}
    >
      <input
        type="file"
        id="uploadExcel"
        style={{ display: "none" }}
        onChange={uploadBankExcel}
        accept=".xlsx,.xls"
      />
      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        statusOptions={STATUS_OPTIONS}
        countryOptions={countryOptions}
        resetDefault={() => {
          setSearchParams({
            name: "",
            email: "",
            lucaId: "",
            status: "",
            country: "",
          });
          setQueryParams({
            name: "",
            email: "",
            lucaId: "",
            status: "",
            country: "",
          });

          setPage(1);
        }}
      />
      <ListView
        isLoading={isLoading}
        data={data?.data?.commissionDetails}
        columns={columns}
        totalItems={data?.data?.totalItems}
        enablePagination={true}
        itemsPerPage={pageSize}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <InvoiceCard
            key={item.id}
            name={item.commission?.client?.name}
            email={item.email}
            invoiceId={item.commission?.invoiceId}
            finalAmount={item.commission?.finalAmount}
            status={item.status}
            onDetailClick={() =>
              navigate(
                `/payments/${item.commission.paymentId}/invoices/${item.id}`
              )
            }
          />
        )}
      />

      {/* Payment History Dialog */}
      <Dialog
        open={isPaymentHistoryOpen}
        onOpenChange={setIsPaymentHistoryOpen}
      >
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Payment History</DialogTitle>
            <DialogDescription>
              View payment history groups and download invoice PDFs
            </DialogDescription>
          </DialogHeader>

          {isLoadingPaymentHistory ? (
            <div className="flex justify-center p-4">
              Loading payment history...
            </div>
          ) : (
            <Table>
              <TableCaption>List of payment history groups</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Payment Date</TableHead>
                  <TableHead>Records</TableHead>
                  <TableHead>Total Amount</TableHead>
                  <TableHead>Currencies</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {paymentHistoryGroups?.data?.length > 0 ? (
                  paymentHistoryGroups.data.map((group) => (
                    <TableRow key={group.groupId}>
                      <TableCell>{formatDate(group.createdAt)}</TableCell>
                      <TableCell>{group.paymentDate || "-"}</TableCell>
                      <TableCell>{group.recordCount}</TableCell>
                      <TableCell>{group.totalAmount.toFixed(2)}</TableCell>
                      <TableCell>
                        {group.currencies.join(", ") || "-"}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              handleViewGroupDetails(group.groupId)
                            }
                          >
                            View Details
                          </Button>
                          <Button
                            variant="default"
                            size="sm"
                            onClick={() =>
                              handleDownloadGroupPdfs(group.groupId)
                            }
                          >
                            Download PDFs
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center">
                      No payment history groups found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>

      {/* Group Details Dialog */}
      <Dialog open={isGroupDetailsOpen} onOpenChange={setIsGroupDetailsOpen}>
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Payment Group Details</DialogTitle>
            <DialogDescription>
              Details for payment group {selectedGroupId}
            </DialogDescription>
          </DialogHeader>

          {isLoadingGroupDetails ? (
            <div className="flex justify-center p-4">
              Loading group details...
            </div>
          ) : groupDetails?.data ? (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-md">
                <div>
                  <p className="text-sm font-medium">Group ID:</p>
                  <p className="text-sm">{groupDetails.data.groupId}</p>
                </div>
                <div>
                  <p className="text-sm font-medium">Payment Date:</p>
                  <p className="text-sm">
                    {groupDetails.data.paymentDate || "-"}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium">Total Amount:</p>
                  <p className="text-sm">
                    {groupDetails.data.totalAmount.toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium">Currencies:</p>
                  <p className="text-sm">
                    {groupDetails.data.currencies.join(", ") || "-"}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium">Record Count:</p>
                  <p className="text-sm">{groupDetails.data.recordCount}</p>
                </div>
                <div>
                  <p className="text-sm font-medium">Created At:</p>
                  <p className="text-sm">
                    {formatDate(groupDetails.data.batchCreatedAt)}
                  </p>
                </div>
              </div>

              <Button
                className="w-full"
                onClick={() =>
                  handleDownloadGroupPdfs(groupDetails.data.groupId)
                }
              >
                Download All PDFs
              </Button>

              <Table>
                <TableCaption>Payment records in this group</TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead>Invoice ID</TableHead>
                    <TableHead>Client</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Currency</TableHead>
                    <TableHead>Bank Name</TableHead>
                    <TableHead>Recipient</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {groupDetails.data.paymentHistories.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>
                        {payment.commissionDetail?.invoiceId || "-"}
                      </TableCell>
                      <TableCell>
                        {payment.commissionDetail?.commission?.Client?.name ||
                          "-"}
                      </TableCell>
                      <TableCell>{payment.amount.toFixed(2)}</TableCell>
                      <TableCell>{payment.currency || "-"}</TableCell>
                      <TableCell>{payment.bankName || "-"}</TableCell>
                      <TableCell>{payment.recipientName || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="text-center p-4">No data found for this group</div>
          )}
        </DialogContent>
      </Dialog>

      {/* Invoice Selection Dialog */}
      <Dialog
        open={isInvoiceSelectionOpen}
        onOpenChange={setIsInvoiceSelectionOpen}
      >
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Select Invoices</DialogTitle>
            <DialogDescription>
              Select invoices to include in the Excel download
            </DialogDescription>
          </DialogHeader>

          {isLoadingInvoices ? (
            <div className="flex justify-center p-4">
              Loading invoice data...
            </div>
          ) : (
            <>
              <div className="flex justify-between mb-4">
                <Button variant="outline" onClick={selectAllInvoices}>
                  Select All
                </Button>
                <Button variant="outline" onClick={clearAllInvoices}>
                  Clear All
                </Button>
              </div>

              {groupedInvoices && groupedInvoices.length > 0 ? (
                <div className="space-y-2">
                  {groupedInvoices.map((item) => (
                    <div
                      key={item.invoiceId}
                      className="flex items-center space-x-2 p-2 border rounded hover:bg-gray-50"
                    >
                      <Checkbox
                        id={`invoice-${item.invoiceId}`}
                        checked={selectedInvoiceIds.includes(item.invoiceId)}
                        onCheckedChange={() =>
                          toggleInvoiceSelection(item.invoiceId)
                        }
                      />
                      <Label
                        htmlFor={`invoice-${item.invoiceId}`}
                        className="flex-1 cursor-pointer"
                      >
                        {item.invoiceId}
                      </Label>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center p-4">
                  No invoices found matching the current filters
                </div>
              )}

              <div className="mt-4">
                <p className="text-sm font-medium mb-2">
                  Selected Invoice IDs:{" "}
                  <span className="text-blue-600 font-medium">
                    {selectedInvoiceIds.length}
                  </span>{" "}
                  selected
                </p>
                {selectedInvoiceIds.length > 0 && (
                  <div className="p-2 bg-gray-50 rounded-md max-h-[100px] overflow-y-auto">
                    <p className="text-sm break-all font-mono">
                      {selectedInvoiceIds.join(",")}
                    </p>
                  </div>
                )}
              </div>

              <DialogFooter className="mt-4">
                <Button
                  variant="outline"
                  onClick={() => setIsInvoiceSelectionOpen(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleDownloadSelectedInvoices}>
                  Download Excel
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </AppLayout>
  );
}
