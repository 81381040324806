import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  LoginScreen,
  RegisterScreen,
  LandingPageScreen,
  AboutPageScreen,
  HomeScreen,
  UserListScreen,
  RoleListScreen,
  RoleUpdateScreen,
  RoleAddScreen,
  SettingScreen,
  ProfileScreen,
  AdminDashboardScreen,
  UserUpdateScreen,
  UserAddScreen,
  ClientListScreen,
  ClientAddScreen,
  ClientUpdateScreen,
  AgencyAddScreen,
  AgencyUpdateScreen,
  AgencyListScreen,
  GptPromptListScreen,
  GptPromptAddScreen,
  GptPromptUpdateScreen,
} from "../screens";

import AuthStore from "../stores/AuthStore";
import { useObserver } from "mobx-react";
import { ProtectedRoute } from "./protected-route";
import { ProtectedAdminRoute } from "./protected-admin-route";
import {
  CommissionDetailScreen,
  CommissionErrorListScreen,
  CommissionInvoiceUploadManuelPdfENScreen,
  CommissionInvoiceUploadManuelPdfScreen,
  CommissionInvoiceUploadScreen,
  CommissionListScreen,
  ConfirmationAddScreen,
  ConfirmationDetailScreen,
  ConfirmationEmailConfirmScreen,
  ConfirmationListScreen,
  InvoiceDetailScreen,
  InvoiceListAllScreen,
  InvoiceListScreen,
  PaymentAddScreen,
  PaymentDetailScreen,
  PaymentListScreen,
} from "@/screens/app";
import { InvoicePdf } from "@/components";
import { InvoicePdfPreview } from "@/components/invoice-pdf-preview";
import { InvoicePdfPreviewEN } from "@/components/invoice-pdf-preview-en";
import {
  AccountingScreen,
  AccountingOnePageScreen,
  InvoiceListOperationScreen,
} from "@/screens/app/invoices";

export function BaseRouter() {
  return useObserver(() => (
    <Routes>
      <Route path="/">
        <Route exact path="/" element={<LandingPageScreen />} />

        <Route path="/about" element={<AboutPageScreen />} />
        <Route
          path="/login"
          element={
            !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
              <LoginScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/register"
          element={
            !AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
              <RegisterScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <HomeScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path="clients"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CLIENT_READ">
                <ClientListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CLIENT_READ">
                <ClientUpdateScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CLIENT_CREATE">
                <ClientAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="agencies"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="AGENCY_READ">
                <AgencyListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="agencies/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="AGENCY_READ">
                <AgencyUpdateScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="agencies/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="AGENCY_CREATE">
                <AgencyAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />

        <Route
          path="payments"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="PAYMENT_READ">
                <PaymentListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="PAYMENT_READ">
                <PaymentDetailScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/commissions"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="COMMISSION_READ">
                <CommissionListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/commissions/error-commissions"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="COMMISSION_READ">
                <CommissionErrorListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/commissions/:commissionId"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="COMMISSION_READ">
                <CommissionDetailScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/commissions/:commissionId/upload"
          element={<CommissionInvoiceUploadScreen />}
        />
        <Route path="invoice/*" element={<CommissionInvoiceUploadScreen />} />
        <Route path="invoiceabc" element={<InvoicePdfPreview />} />
        <Route path="invoiceabcEN" element={<InvoicePdfPreviewEN />} />
        <Route
          path="invoiceManuel/*"
          element={<CommissionInvoiceUploadManuelPdfScreen />}
        />
        <Route
          path="invoiceManuelEn/*"
          element={<CommissionInvoiceUploadManuelPdfENScreen />}
        />

        <Route
          path="payments/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="PAYMENT_CREATE">
                <PaymentAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/invoices"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <InvoiceListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices-all"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <InvoiceListAllScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices-all/accounting"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <AccountingScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices-all/accounting-onepage"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <AccountingOnePageScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="invoices-operations"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <InvoiceListOperationScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="payments/:id/invoices/:invoiceId"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="INVOICE_READ">
                <InvoiceDetailScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="confirmations"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CONFIRMATION_READ">
                <ConfirmationListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="confirmations/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CONFIRMATION_CREATE">
                <ConfirmationAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="confirmations/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="CONFIRMATION_READ">
                <ConfirmationDetailScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />

        <Route path="confirm/*" element={<ConfirmationEmailConfirmScreen />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfileScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <HomeScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfileScreen />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="admin">
        <Route
          exact
          path=""
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="Permission_Admin_Dashboard">
                <AdminDashboardScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="Permission_Admin_Dashboard">
                <SettingScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="Permission_UserRead">
                <UserListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="users/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="Permission_UserRead">
                <UserUpdateScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="users/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="USER_CREATE">
                <UserAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="ROLE_READ">
                <RoleListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="ROLE_CREATE">
                <RoleAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="ROLE_UPDATE">
                <RoleUpdateScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="gptprompts"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="GPTPROMPT_READ">
                <GptPromptListScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="gptprompts/add"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="GPTPROMPT_CREATE">
                <GptPromptAddScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
        <Route
          path="gptprompts/:id"
          element={
            <ProtectedRoute>
              <ProtectedAdminRoute permission="GPTPROMPT_UPDATE">
                <GptPromptUpdateScreen />
              </ProtectedAdminRoute>
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="*" element={<div>Not Found</div>} />
    </Routes>
  ));
}
