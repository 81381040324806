import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { SearchView } from "@/components";
import ListView from "@/components/list-view";
import PDFViewerCustom from "@/components/pdf-viewer";
import {
  approveInvoice,
  getAllInvoices,
} from "@/data/requests/invoice-requests";
import { FullScreenLayout } from "../shared/fullscreen-layout";
import { countryOptions } from "@/utils/country-options";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const STATUS_OPTIONS = [
  { value: "Approved", label: "Approved" },
  { value: "Change Requested", label: "Change Requested" },
  { value: "Troubled", label: "Troubled" },
  { value: "Invoice Uploaded", label: "Invoice Uploaded" },
  { value: "Mail Sended", label: "Mail Sended" },
  { value: "Accounting", label: "Accounting" },
  { value: "Auto Created Invoice", label: "Auto Created Invoice" },
  { value: "OCR Completed", label: "OCR Completed" },
  { value: "LUCA", label: "LUCA" },
  { value: "Paid", label: "Paid" },
  { value: "all", label: "All" },
];

export function AccountingScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    country: "",
  });
  const [queryParams, setQueryParams] = useState({});
  const [selectedCommissionDetail, setSelectedCommissionDetail] =
    useState(null);
  const pdfRef = useRef(null);
  const [pdfContainerWidth, setPdfContainerWidth] = useState(null);

  useEffect(() => {
    const updateWidth = () => {
      if (pdfRef.current) {
        setPdfContainerWidth(pdfRef.current.clientWidth);
      }
    };

    // Initial width calculation
    updateWidth();

    // Update width on window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup
    return () => window.removeEventListener("resize", updateWidth);
  }, [selectedCommissionDetail]);

  const { data, isLoading } = useQuery({
    queryKey: ["invoices", page, pageSize, queryParams],
    queryFn: () =>
      getAllInvoices({
        page,
        pageSize,
        nameFilter: queryParams.name,
        emailNameFilter: queryParams.email,
        lucaIdFilter: queryParams.lucaId,
        statusFilter: "Accounting",
        countryFilter: queryParams.country,
      }),
  });

  const approveInvoiceMutation = useMutation({
    mutationFn: approveInvoice,
    onSuccess: () => {
      queryClient.invalidateQueries(["invoices-all"]);
    },
  });

  const handleUpdateStatus = (invoiceId, newStatus) => {
    approveInvoiceMutation.mutate({
      id: invoiceId,
      data: { status: newStatus },
    });
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const handleRowClick = (commissionDetail) => {
    setSelectedCommissionDetail(commissionDetail);
  };

  const columns = [
    {
      key: "lucaId",
      label: "Luca ID",
      accessor: (item) => item.commission?.client?.lucaId || "",
    },
    {
      key: "lucaAccountName",
      label: "Luca Account Name",
      accessor: (item) => item.commission?.client?.lucaAccountName || "",
    },
    {
      key: "invoiceId",
      label: "Invoice ID",
      accessor: (item) => item.invoiceId || "",
    },
    {
      key: "invoiceDate",
      label: "Invoice Date",
      accessor: (item) => item.invoiceDate || "",
    },
    {
      key: "totalAmount",
      label: "Total Amount",
      accessor: (item) => item.totalAmount || "",
    },
    {
      key: "commission.client",
      label: "Currency",
      accessor: (item) => item.commission?.client?.currency || "",
    },
    {
      key: "status",
      label: "Status",
      accessor: (item) => item.status || "",
    },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div className="flex gap-2">
          {row.status !== "LUCAREADY" && (
            <Button
              onClick={() => handleUpdateStatus(row.id, "LUCAREADY")}
              disabled={row.status === "LUCAREADY"}
            >
              READY
            </Button>
          )}

          <Button
            size="icon"
            variant="destructive"
            onClick={() =>
              navigate(
                `/payments/${row.commission.paymentId}/invoices/${row.id}`
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 492.49284 492"
              fill="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
              <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
            </svg>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <FullScreenLayout>
      <div className="h-screen">
        <ResizablePanelGroup
          direction="horizontal"
          className="min-h-[800px] rounded-lg border"
        >
          <ResizablePanel defaultSize={50}>
            <div className="h-full p-6 overflow-auto">
              <SearchView
                searchParams={searchParams}
                handleSearch={handleSearch}
                countryOptions={countryOptions}
                resetDefault={() => {
                  setSearchParams({
                    country: "",
                  });
                  setQueryParams({});
                  setPage(1);
                }}
              />
              <ListView
                isLoading={isLoading}
                data={data?.data?.commissionDetails}
                columns={columns}
                onRowClick={handleRowClick}
                totalItems={data?.data?.totalItems}
                enablePagination={true}
                itemsPerPage={pageSize}
                currentPage={page}
                onPageChange={setPage}
              />
            </div>
          </ResizablePanel>

          <ResizableHandle withHandle />

          <ResizablePanel defaultSize={50}>
            <div className="m-1 mr-4  border border-blue-300 rounded-lg h-screen overflow-auto">
              {selectedCommissionDetail &&
              selectedCommissionDetail.invoicePath &&
              selectedCommissionDetail.invoicePath.includes(".pdf") ? (
                <div ref={pdfRef} className="flex h-screen ">
                  <Document
                    file={
                      "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                      selectedCommissionDetail.invoicePath.replace(
                        "public/uploads/paymentPdfs/",
                        ""
                      )
                    }
                  >
                    <Page
                      pageNumber={1}
                      width={pdfContainerWidth || 600}
                      renderTextLayer={false}
                    />
                  </Document>
                </div>
              ) : (
                <div className="flex flex-col gap-1 border border-gray-300 rounded-lg m-4 h-screen pb-20">
                  {selectedCommissionDetail && (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={
                        "https://fantaslinkworld.blob.core.windows.net/invoicememberuploads/" +
                        selectedCommissionDetail.invoicePath.replace(
                          "public/uploads/paymentPdfs/",
                          ""
                        )
                      }
                    />
                  )}
                  {!selectedCommissionDetail && (
                    <Card>
                      <CardHeader>
                        <CardTitle>Invoice Preview</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p>Select an invoice from the list to preview</p>
                      </CardContent>
                    </Card>
                  )}
                </div>
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </FullScreenLayout>
  );
}
