import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { NavbarApp } from "../../../components";
import { getProfile } from "../../../data";
import AuthStore from "../../../stores/AuthStore";
import { cn } from "@/lib/utils";

export const FullScreenLayout = (props) => {
  return (
    <div>
      <NavbarApp />

      <main className="w-full">
        <div className={cn(props.fullscreen ? "w-full" : "")}>
          {props.loading && (
            <div>
              <h1>Loading...</h1>
            </div>
          )}
          {props.children}
        </div>
      </main>
    </div>
  );
};
